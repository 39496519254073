// import { mdiAccount, mdiLock   } from '@mdi/js'

import { mapActions } from "vuex";
import { API_URL } from "../../_plugins/axios";

export default {
  name: "login",
  data() {
    return {
      email: "",
      password: "",
      loading: false,
      error: false,
      show: false,
      valid: false,
      errors: "",
    };
  },
  methods: {
    ...mapActions(["addNotification"]),
    isValid() {
      return this.email.length >= 1 && this.password.length >= 1;
    },
    retrieveToken() {
      this.errors = "";
      if (!this.isValid()) {
        this.errors = "Morate popuniti sva polja!";
        return false;
      }
      this.loading = true;
      this.$store
        .dispatch("auth/retrieveToken", {
          email: this.email,
          password: this.password,
        })
        .then(() => {
          window.location.href = "/";
          API_URL.get("notifications").then((res) => {
            this.addNotification(res.data.data);
          });
          this.loading = false;
          this.$router.push({ name: "Home" });
        })
        .catch(() => {
          this.error = true;
          this.loading = false;
        });
    },
  },
};
